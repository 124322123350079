@import "src/assets/styles/custom/variables";

.profile-info {
  &__content {
    display: flex;
  }

  &__left {
    width: 31.42857142857143%;
  }

  &__right {
    width: 68.57142857142857%;
    padding-left: 24px;
  }

  &__code {
    font-size: 24px;
    line-height: 36px;
  }

  &__update-btn {
    min-width: 354px;
  }

  &__footer {
    padding-bottom: 48px;
  }

  &__management {
    padding-top: 48px;

    &-title {
      margin-bottom: 19px;
      padding: 4px 0;
      font-family: $altFont;
      font-size: 20px;
      line-height: 30px;
      font-weight: 600;
      border-bottom: 1px dashed $border2;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-text {
      padding-right: 30px;
      font-size: 14px;
      line-height: 20px;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .profile-info {
    &__content {
      display: block;
    }

    &__left {
      width: 100% ;
    }

    &__right {
      width: 100%;
      padding-left: 0;
    }

    &__update-btn {
      min-width: 100%;
    }

    &__management {
      &-content {
        display: block;
      }

      &-text {
        margin-bottom: 20px;
      }
    }
  }
}
