@import "src/assets/styles/custom/variables";

.pi-item {
  display: flex;
  padding: 10px 0;
  border-bottom: 1px solid $greyLight;

  &:last-child {
    border-bottom: 0;
  }

  &__name {
    width: 150px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    flex-shrink: 0;
  }

  &__value {
    padding-left: 26px;
  }

  &__note {
    display: block;
    padding-top: 16px;
    font-size: 12px;
    line-height: 16px;
    color: $greyDark2;
  }
}