@import "src/assets/styles/custom/variables";

.header {
  &__content {
    display: flex;
    padding: 20px 0;
    justify-content: space-between;
    align-items: center;
  }

  &__logo {
    width: 192px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .header {
    height: auto;

    &__logo {
      width: 128px;
      height: 40px;
    }
  }
}