@import "src/assets/styles/custom/variables";

.ReactModal {
  &__Overlay {
    display: flex;
    z-index: 1000;
    align-items: center;
    justify-content: center;
    background-color: rgba(18, 18, 25, 0.8) !important;
  }

  &__Content {
    position: static !important;
    padding: 24px 40px !important;
    border: none !important;
    border-radius: 32px !important;
  }
}

@media (max-width: $mobileBreakpoint) {
  .ReactModal {
    &__Overlay {
      padding: 0 20px;
    }

    &__Content {
      width: 100%;
      padding: 20px 15px !important;
    }
  }
}
