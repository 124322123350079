@import "src/assets/styles/custom/variables";

.pmm-item {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: space-between;

  a {
    font-size: 14px;
    line-height: 20px;
    text-decoration: none;
    color: $mainDark;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__arrow {
    display: inline-block;
    width: 12px;
    height: 12px;
    background: url("../../../../assets/images/svg/chevron-right.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }
}
