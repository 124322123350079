@import "src/assets/styles/custom/variables";

.completion-card {
  padding: 32px;
  margin-bottom: 32px;
  border-radius: 32px;
  background-color: $bg;

  &__title {
    font-family: $altFont;
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
    margin-bottom: 16px;
  }

  &__text {
    p {
      margin-bottom: 16px;
    }
  }

  &__actions {
    padding-top: 19px;
    border-top: 1px solid $greyLight;
  }

  &__btn {
    font-family: $altFont;
    font-weight: 600;
    border: none;
    background-color: transparent;
    color: $primary;
  }
}

@media (max-width: $mobileBreakpoint) {
  .completion-card {
    &__title {
      font-size: 20px;
      line-height: 32px;
      margin-bottom: 8px;
    }
  }
}
