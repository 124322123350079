@import "src/assets/styles/custom/variables";

.profile-layout {
  &__row {
    display: flex;
  }

  &__sidebar {
    flex-shrink: 0;
    width: 248px;
    padding: 32px 16px;
    background-color: $bg2;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
  }

  &__content {
    width: calc(100 - 248px);
    padding-left: 56px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .profile-layout {
    &__row {
      display: block;
    }

    &__sidebar {
      width: 100%;
      border-radius: 32px;
    }

    &__content {
      width: 100%;
      padding-left: 0;
    }
  }
}
