@import "src/assets/styles/custom/variables";

.select-menu {
  position: relative;

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__control {
    height: 48px;
    border-radius: 12px !important;
    background: $bg !important;
    border: 1px solid $bg !important;
    box-shadow: none !important;
  }

  &__value-container {
    padding: 0 16px !important;
  }

  &__placeholder {
    margin: 0;
  }

  &__indicator-separator {
    display: none;
  }

  &__error {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    color: $error;
  }

  &--error {
    .select-menu__control {
      border-color: $error !important;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .select-menu {
    &__control {
      height: 40px;
    }
  }
}
