@import "src/assets/styles/custom/variables";

.profile-card {
  margin-bottom: 24px;
  border: 1px solid $border;
  border-radius: 12px;
  overflow: hidden;

  &__head {
    padding: 16px;
    border-bottom: 1px dashed $border2;
  }

  &__title {
    font-family: $altFont;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }

  &__body {
    padding: 16px;
  }
}