@import "src/assets/styles/custom/variables";

.payment-form {
  .input {
    margin-bottom: 16px;
  }

  &__fields {
    max-width: 320px;
    margin-bottom: 32px;

    .button {
      width: 100%;
      margin-top: 16px;
    }
  }

  &__guarantee {
    display: flex;
    padding-top: 20px;
    border-top: 1px solid $greyLight;
    align-items: center;
    justify-content: space-between;
  }

  &__guarantee-icons {
    .icon {
      margin-right: 12px;
    }
  }

  &__guarantee-notice {
    display: flex;
    align-items: center;
    color: $greyDark2;

    .icon {
      margin-right: 12px;
    }
  }

  &__stripe-wrap {
    margin-bottom: 20px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .payment-form {
    &__guarantee {
      display: block;
    }

    &__guarantee-icons {
      margin-bottom: 16px;

      .icon {
        width: 38px !important;
        height: 28px !important;
        margin-right: 10px;
      }
    }
  }
}
