.icon {
  display: inline-block;

  &--tag {
    background: url("../../../assets/images/svg/tag.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &--visa {
    background: url("../../../assets/images/svg/visa.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &--maestro {
    background: url("../../../assets/images/svg/maestro.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &--jcb {
    background: url("../../../assets/images/svg/jcb.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &--lock {
    background: url("../../../assets/images/svg/lock.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &--ticket {
    background: url("../../../assets/images/svg/ticket.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &--invoice {
    background: url("../../../assets/images/svg/invoice.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }
};
