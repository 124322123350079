html {
  height: 100%;
}

body {
  font-family: $mainFont;
  font-size: 16px;
  line-height: 24px;
  color: $mainDark;
}

p, div, h1, h2, h3, h4, h5, ul, a, li, ul, ol, span, section, label, main, aside, footer, header, form, input, textarea, button {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;

  &:before, &:after {
    box-sizing: border-box
  }

  &:focus {
    outline: none;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button {
  cursor: pointer;
}

.container {
  max-width: 1160px;
  margin: 0 auto;
  padding: 0 16px;
}

.title {
  font-family: $altFont;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: $mainDark;
}

@media (max-width: $mobileBreakpoint) {
  body {
    font-size: 14px;
    line-height: 20px;
  }

  .title {
    font-size: 24px;
    line-height: 32px;
  }
}
