@import "src/assets/styles/custom/variables";

.profile-header {
  padding: 25px 0 15px;
  margin-bottom: 27px;
  border-bottom: 2px solid $greyDark;

  &__title {
    font-family: $altFont;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
  }
}
