@import "src/assets/styles/custom/variables";

.profile-menu {
  &__top {
    margin-bottom: 400px;
  }

  &__main {
    margin-bottom: 8px;
    padding: 14px 12px;
    background-color: $white;
    border-radius: 16px;

    &-title {
      display: flex;
      align-items: center;
      font-family: $altFont;
      font-size: 14px;
      line-height: 20px;

      &::before {
        content: '';
        display: inline-block;
        width: 16px;
        height: 16px;
        margin-right: 14px;
        background: url("../../../assets/images/svg/male-avatar-circle.svg") 50% 50% no-repeat;
        background-size: 100% 100%;
      }
    }

    &-items {
      padding-top: 24px;
      padding-left: 32px;
    }
  }

  &__item {
    display: flex;
    padding: 14px;
    margin-bottom: 8px;
    font-size: 14px;
    line-height: 20px;
    color: $greyDark2;
    align-items: center;
    text-decoration: none;
    border: none;
    background-color: transparent;

    .icon, .svg-icon {
      margin-right: 14px;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .profile-menu {
    &__top {
      margin-bottom: 0;
    }
  }
}
