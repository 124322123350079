@import "src/assets/styles/custom/variables";

.pe-field {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  &__value {
    font-size: 20px;
    line-height: 32px;
  }

  &__btn {
    background-color: transparent;
    border: none;
    font-size: 14px;
    line-height: 20px;
    font-weight: 700;
    color: $primary;
  }
}
