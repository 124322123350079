@import "src/assets/styles/custom/variables";

.signin {
  display: flex;
  align-items: center;
  justify-content: center;

  &__logo {
    width: 256px;
    height: 80px;
    margin-bottom: 72px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .form-card {
    min-height: 0;
    width: 456px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .signin {
    &__content {
      width: 100%;
    }

    .form-card {
      width: 100%;
    }
  }
}
