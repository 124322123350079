@import "src/assets/styles/custom/variables";

.apd-card {
  padding: 32px;
  margin-bottom: 32px;
  border-radius: 32px;
  background-color: $white;
  box-shadow: 0 12px 32px -8px rgba(26, 26, 26, 0.24);

  &__title {
    font-family: $altFont;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    margin-bottom: 24px;
  }

  &__content {
    display: flex;
  }

  &__col {
    padding: 16px;

    &:first-child {
      width: 40%;
      padding: 0 16px 0;
    }

    &:last-child {
      width: 60%;
      border: 1px solid $greyLight;
      border-radius: 16px;
    }

    a svg {
      width: 100%;
      height: 80px;
      background-color: $black;
      border-radius: 16px;
    }
  }

  &__section-title {
    font-family: $altFont;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 16px;
  }

  &__code {
    img {
      width: 100%;
      height: auto;
    }
  }

  .input {
    margin-bottom: 16px;
  }

  &__status {
    margin-top: 25px;
    display: flex;

    span {
      vertical-align: middle;
      
      &:last-child {
        margin-left: 10px;
      }
    }
  }

  &__btn {
    font-family: $altFont;
    font-weight: 600;
    border: none;
    background-color: transparent;
    color: $primary;
  }

  &__success {
    margin-top: 25px;
    padding-bottom: 25px;
    display: flex;
    border-bottom: 1px solid $greyLight;
  }

  &__resend {
    margin-top: 20px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .apd-card {
    &__title {
      font-size: 24px;
      line-height: 36px;
      margin-bottom: 48px;
    }

    &__content {
      display: block;
    }

    &__col {
      &:first-child {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid $greyDark2;
      }

      &:last-child {
        width: 100%;
        border: none;
      }
    }
  }
}
