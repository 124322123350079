@import "src/assets/styles/custom/variables";

.payment-card {
  &__divider {
    width: 100%;
    height: 2px;
    margin: 35px 0;
    background-color: $greyLight;
  }

  &__title {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .payment-card {
    &__title {
      font-size: 14px;
      line-height: 20px;
    }
  }
}
