@import "src/assets/styles/custom/variables";

.signin-form {
  margin-bottom: 16px;

  &__forgot {
    margin-bottom: 32px;

    a {
      font-size: 14px;
      line-height: 20px;
      color: $mainDark;
    }
  }

  .input {
    margin-bottom: 16px;
  }
}