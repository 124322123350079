@import "src/assets/styles/custom/variables";

.layout {
  width: 100%;
  min-height: 100vh;
  padding-bottom: 48px;
  overflow-x: hidden;

  &--completed {
    background: url("../../../assets/images/jpg/main-background.jpg") 50% 50% no-repeat;
    background-size: cover;

    .layout__content {
      max-width: 744px;
    }
  }

  &--signin {
    background-color: $purple;

    .header {
      display: none;
    }

    .layout {
      &__content {
        width: 100%;
      }

      &__sidebar {
        display: none;
      }
    }
  }

  &__row {
    display: flex;
    margin: 0 -48px;
    padding-top: 30px;
  }

  &__content {
    width: 68.08510638297872%;
    padding: 0 48px;
  }

  &__sidebar {
    width: 31.91489361702128%;
    padding: 0 48px;
  }
}

@media (max-width: $mobileBreakpoint) {
  .layout {
    &__row {
      padding-top: 12px;
      flex-wrap: wrap;
    }

    &__content {
      width: 100%;
    }

    &__sidebar {
      width: 100%;
    }
  }
}
