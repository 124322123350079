@import "src/assets/styles/custom/variables";

.plan-info {
  padding-top: 24px;

  &__title {
    margin-bottom: 24px;
  }

  &__card {
    padding: 16px;
    margin-bottom: 16px;
    border: 1px solid $primary;
    border-radius: 12px;

    &-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid $primary;
    }

    &-period {
      font-family: $altFont;
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      color: $primary;
    }

    &-price {
      font-family: $altFont;
      font-size: 20px;
      line-height: 32px;
      font-weight: 700;
      color: $mainDark;
    }

    &-body {
      padding-top: 16px;
    }

    &-list {
      list-style: none;

      li {
        position: relative;
        padding-left: 14px;
        margin-bottom: 5px;
        font-size: 14px;
        line-height: 20px;
        color: $mainDark;

        &:last-child {
          margin-bottom: 0;
        }

        &::before {
          content: '';
          position: absolute;
          left: 0;
          top: 9px;
          width: 4px;
          height: 4px;
          background-color: $primary;
          border-radius: 100px;
        }
      }
    }
  }

  &__total {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-label {
      font-size: 16px;
      line-height: 24px;
      color: $mainDark;
    }

    &-price {
      font-family: $altFont;
      font-size: 32px;
      line-height: 48px;
      font-weight: 700;
      color: $mainDark;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .plan-info {
    &__card {
      margin-top: 8px;

      &-head {
        padding-bottom: 8px;
      }

      &-period {
        font-size: 16px;
        line-height: 24px;
      }

      &-price {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &__total {
      &-label {
        font-size: 14px;
        line-height: 20px;
      }

      &-price {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }
}
