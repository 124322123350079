.svg-icon {
  position: relative;
  display: inline-block;

  svg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}
