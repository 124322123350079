@import "src/assets/styles/custom/variables";

.voucher-field {
  display: flex;
  align-items: flex-end;

  &__wrap {
    max-width: 328px;
    padding: 16px;
    margin-right: 30px;
    flex-wrap: wrap;
    background-color: $bg2;
    border-radius: 8px;
  }

  &__title {
    display: flex;
    align-items: center;
    font-family: $altFont;
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
    margin-bottom: 8px;

    .icon {
      margin-right: 8px;
    }
  }

  input {
    background-color: $white;
  }
}

@media (max-width: $mobileBreakpoint) {
  .voucher-field {
    max-width: 100%;
  }
}
