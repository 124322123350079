@import "src/assets/styles/custom/variables";

.payment-placeholder {
  padding: 32px;
  margin-top: 32px;
  border-radius: 32px;
  background-color: $bg;

  &__text {
    font-family: $altFont;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    color: $greyDark;
  }
}

@media (max-width: $mobileBreakpoint) {
  .payment-placeholder {
    padding: 24px 32px;

    &__text {
      font-size: 24px;
      line-height: 36px;
    }
  }
}
