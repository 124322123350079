@import "../../../../assets/styles/custom/variables";

.button {
  height: 48px;
  padding: 0 20px;
  font-family: $altFont;
  font-size: 16px;
  font-weight: 600;
  line-height: 46px;
  border-radius: 12px;
  color: $white;
  background-color: $primary;
  border: 1px solid $primary;
  cursor: pointer;

  &--secondary {
    background-color: $primaryLight;
    border-color: $primaryLight;
    color: $primary;
  }

  &--empty {
    padding: 0;
    background-color: transparent;
    border-color: transparent;
    color: $primary;
  }

  &--danger {
    background-color: $errorLight;
    border-color: $errorLight;
    color: $error;
  }

  &:disabled {
    cursor: default;
    background-color: $greyDark;
    border-color: $greyDark;
  }
}

@media (max-width: $mobileBreakpoint) {
  .button {
    height: 40px;
    font-size: 14px;
    line-height: 38px;
    padding: 0 10px;
  }
}