@font-face {
  font-family: 'Epilogue';
  src: url("../../fonts/Epilogue/Epilogue-SemiBold.ttf") format('ttf'),
  url("../../fonts/Epilogue/Epilogue-SemiBold.woff") format('woff'),
  url("../../fonts/Epilogue/Epilogue-SemiBold.eot") format('eot');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Epilogue';
  src: url("../../fonts/Epilogue/Epilogue-Bold.ttf") format('ttf'),
  url("../../fonts/Epilogue/Epilogue-Bold.woff") format('woff'),
  url("../../fonts/Epilogue/Epilogue-Bold.eot") format('eot');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url("../../fonts/Inter/Inter-Regular.ttf") format('ttf'),
  url("../../fonts/Inter/Inter-Regular.woff") format('woff'),
  url("../../fonts/Inter/Inter-Regular.eot") format('eot');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Inter';
  src: url("../../fonts/Inter/Inter-SemiBold.ttf") format('ttf'),
  url("../../fonts/Inter/Inter-SemiBold.woff") format('woff'),
  url("../../fonts/Inter/Inter-SemiBold.eot") format('eot');
  font-weight: 600;
  font-style: normal;
}