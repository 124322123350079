@import "src/assets/styles/custom/variables";

.user-menu {
  position: relative;

  &__btn {
    display: flex;
    height: 48px;
    padding: 0 18px;
    border-radius: 16px;
    background-color: $white;
    border: none;
    align-items: center;
    color: $mainDark;
    box-shadow: 0 4px 5px 0 rgba(26, 26, 26, 0.2);

    &-text {
      margin: 0 10px;
      font-size: 14px;
      line-height: 20px;
    }

    &--active {
      color: $white;
      background-color: $primary;

      .user-menu__btn-arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__list {
    position: absolute;
    right: 0;
    top: 100%;
    min-width: 216px;
    margin-top: 14px;
    padding-top: 14px;
    background-color: $white;
    list-style: none;
    border-radius: 8px;
    border: 1px solid $border;

    li {
      padding: 0 18px;
      margin-bottom: 20px;

      &:last-child {
        padding-top: 20px;
        border-top: 1px solid $greyDark;
      }

      a, button {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        color: $mainDark;
        text-decoration: none;
        border: none;
        background-color: transparent;
      }

      .svg-icon {
        margin-right: 10px;
      }
    }
  }
}
