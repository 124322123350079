@import "../../../../assets/styles/custom/variables";

.input {
  position: relative;

  input {
    height: 48px;
    width: 100%;
    padding: 0 16px;
    border-radius: 12px;
    background: $bg;
    border: 1px solid $bg;
    font-size: 16px;

    &[type="password"] {
      color: $greyDark;
    }

    &::placeholder {
      color: $greyDark;
    }
  }

  &--reset {
    input {
      padding-right: 40px;
    }
  }

  &--completed {
    input {
      background-color: rgba(20, 76, 217, 0.05);
    }
  }

  &--disabled {
    pointer-events: none;

    input {
      background-color: $bg;
    }

    .input__check {
      background: url("../../../../assets/images/svg/check-circle-grey.svg") 50% 50% no-repeat;
      background-size: 100% 100%;
    }
  }

  &__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 8px;
    font-weight: 600;
  }

  &__reset-btn {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 14px;
    right: 12px;
    border: none;
    cursor: pointer;
    background: url("../../../../assets/images/svg/remove.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &__check {
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 14px;
    right: 12px;
    background: url("../../../../assets/images/svg/check-circle.svg") 50% 50% no-repeat;
    background-size: 100% 100%;
  }

  &__error {
    position: absolute;
    left: 0;
    top: 100%;
    font-size: 12px;
    color: $error;
    white-space: nowrap;
  }

  &--error {
    input {
      border-color: $error;
    }
  }
}

@media (max-width: $mobileBreakpoint) {
  .input {
    input {
      height: 40px;
      font-size: 14px;
    }

    &__reset-btn {
      bottom: 10px;
    }

    &__check {
      bottom: 10px;
    }
  }
}
