@import "src/assets/styles/custom/variables";

.quest-action {
  margin-bottom: 32px;

  &__text {
    margin-bottom: 16px;
  }

  &__btn {
    border: none;
    background-color: transparent;
    color: $primary;
  }
}
