$mainFont: 'Inter', sans-serif;
$altFont: 'Epilogue', sans-serif;

$white: #ffffff;
$black: #000000;
$primary: #57CD66;
$purple: #463DA3;
$primaryLight: rgba(87, 205, 102, 0.1);
$mainDark: #121219;
$info: #144CD9;
$grey: #d4d4d5;
$greyLight: #DADADC;
$greyDark: #A4A4A7;
$greyDark2: #858589;
$bg: #f4f4f4;
$bg2: #F6F5FA;
$border: #C2C2C4;
$border2: #A5ADAD;
$orange: #F26F46;
$error: #D80936;
$errorLight: rgba(216, 9, 54, 0.1);

$mobileBreakpoint: 676px;
