@import "src/assets/styles/custom/variables";

.form-card {
  min-height: 722px;
  padding: 32px;
  border-radius: 32px;
  box-shadow: 1px 2px 29px -15px rgba(0, 0, 0, 0.75);
  background-color: $white;

  &__head {
    display: flex;
    padding-bottom: 20px;
    margin-bottom: 16px;
    border-bottom: 1px solid $greyLight;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: $mobileBreakpoint) {
  .form-card {
    &__head {
      display: block;
      padding-bottom: 14px;
      margin-bottom: 14px;
      border-bottom: none;
    }
  }
}
